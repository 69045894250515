$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);

.main {
  > * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  > .loading {
    margin-top: 1rem;
    text-align: center;
    font-size: 20px;
    color: rgb(255, 127, 80, 0.7);
  }

  > .playerButtons {
    margin: auto;
    margin-top: 1rem;
    display: flex;
    width: 450px;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(33, 35, 41);
    padding: 8px;
    border-radius: 8px;
    transition: all 200ms ease;

    > img {
      filter: invert(75%);
      width: 24px;

      &:hover {
        cursor: pointer;
        filter: invert(100%);
      }
    }

    > input {
      accent-color: rgb(150, 149, 155);
      width: 100px;
    }
  }

  > .header {
    > a {
      background: $container_color;
      font-size: 1.4rem;
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      letter-spacing: 1px;
      text-decoration: none;
      color: $color;
      transition: all 200ms;
      border-bottom: rgba(255, 255, 255, 0) 1px solid;

      &:hover {
        background: darkcyan;
        color: white;
        border-bottom: rgba(255, 255, 255, 0.5) 1px solid;
      }
    }
  }

  > .items {
    margin: auto;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    margin-bottom: 1rem;

    > .item {
      padding: 0.5rem 0;
      background: $container_color;
      color: $color;
      border: $bg_color 1px solid;
      font-size: 1rem;
      text-transform: capitalize;
      transition: all 200ms;
      width: 200px;

      &:hover {
        cursor: pointer;
        background: darkcyan;
        color: white;
        border: rgba(255, 255, 255, 0.5) 1px solid;
      }
    }
  }
}
