$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);

.main {
	margin-bottom: 1rem;

	.title {
		margin-top: 1rem;
		font-size: 2rem;
		letter-spacing: 1px;
		text-align: center;

		a {
			color: $color;
			text-decoration: none;
			background: $container_color;
			padding: 0 1rem;
			border-radius: 0.7rem;
			transition: all 200ms ease;

			&:hover {
				background: $color;
				color: $container_color;
			}
		}
	}

	.subtitle {
		margin-top: 0.5rem;
		text-align: center;
		font-size: 1.5rem;
	}

	form {
		margin-top: 1rem;
		display: flex;
		justify-content: center;

		input {
			border: none;
			background: $container_color;
			color: $color;
			padding: 0.5rem 1rem;
			border-radius: 1rem 0 0 1rem;
			outline: none;
		}

		button {
			cursor: pointer;
			border: none;
			background: $container_color;
			color: $color;
			padding: 0.5rem 1rem;
			border-radius: 0 1rem 1rem 0;
			border-left: 1px dashed $bg_color;
			transition: all 200ms ease;

			&:hover {
				background: $color;
				color: $container_color;
			}
		}
	}

	.items {
		margin-top: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;

		.item {
			background: $container_color;
			padding: 0.5rem 1rem;
			border-radius: 0.3rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			min-width: 350px;
			margin: 0 1rem;

			.button {
				cursor: pointer;
				width: 50px;
				height: 0;
				background: $bg_color;
				border-radius: 1rem;
				color: $color;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 1rem;

				&.del {
					color: rgb(106, 106, 255);

					&:hover {
						background: rgb(106, 106, 255);
						color: $bg_color;
					}
				}

				&.check {
					color: rgb(255, 105, 105);

					&:hover {
						background: rgb(255, 105, 105);
						color: $bg_color;
					}
				}

				&.checked {
					color: greenyellow;

					&:hover {
						background: greenyellow;
						color: $bg_color;
					}
				}
			}

			p {
				text-align: center;
			}
		}

		@media (max-width: 400px) {
			.item {
				min-width: 95%;
			}
		}
	}
}
