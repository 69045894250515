$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);
$sun_filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);

:root {
  // scrollbar-color: $color $bg_color;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  background: $bg_color;
}

::-webkit-scrollbar-thumb {
  background: $color;
  -webkit-border-radius: 8px;
  // -webkit-box-shadow: 0px 1px 2px white;

  &:hover {
    background: rgb(104, 103, 107);
  }

  &:active {
    background: rgb(65, 64, 67);
  }
}

::-webkit-scrollbar-corner {
  background: red;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif, serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg_color;
  color: $color;
}
