$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);
$sun_filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);

.main {
  header {
    display: flex;

    a {
      color: $color;
      text-decoration: none;
      background-color: $container_color;
      border-radius: 1rem;
      padding: 0.8rem 1rem;
      margin: 1.5rem auto;
      font-size: 2rem;
      transition: all 200ms;
      font-weight: 700;

      &:hover {
        background: darkcyan;
        color: white;
        transition: all 200ms;
      }
    }
  }

  main {
    margin-top: 1.5rem;
    margin-bottom: 4rem;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      input {
        background: $container_color;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem 0 0 0.5rem;
        color: $color;
        font-size: 1rem;
        font-weight: 500;

        &:focus {
          outline: none;
        }
      }

      button {
        margin-left: 0.2rem;
        background: $container_color;
        border: none;
        color: $color;
        border-radius: 0 0.5rem 0.5rem 0;
        transition: all 200ms;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem 1rem;

        &:hover {
          cursor: pointer;
          background: darkcyan;
          color: white;
          transition: all 200ms;
        }
      }

      & + .form {
        margin-top: 1rem;
      }

      .row {
        display: flex;
      }
    }
  }
}
