$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);
$sun_filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);

.main {
  header {
    display: flex;

    a {
      color: $color;
      text-decoration: none;
      background-color: $container_color;
      border-radius: 1rem;
      padding: 0.8rem 1rem;
      margin: 1.5rem auto;
      font-size: 2rem;
      transition: all 200ms;
      font-weight: 700;

      &:hover {
        background: darkcyan;
        color: white;
        transition: all 200ms;
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    a {
      color: $color;
      text-decoration: none;
      background-color: $container_color;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      transition: all 200ms;
      font-weight: 600;

      &:hover {
        background: darkcyan;
        color: white;
        transition: all 200ms;
      }
    }
  }

  @media (max-width: 470px) {
    nav {
      flex-direction: column;
      align-items: center;

      a {
        & + a {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }

  main {
    margin-top: 1.5rem;
    margin-bottom: 4rem;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      input {
        background: $container_color;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem 0 0 0.5rem;
        color: $color;
        font-size: 1rem;
        font-weight: 500;

        &:focus {
          outline: none;
        }
      }

      button {
        margin-left: 0.2rem;
        background: $container_color;
        border: none;
        color: $color;
        border-radius: 0 0.5rem 0.5rem 0;
        transition: all 200ms;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem 1rem;

        &:hover {
          cursor: pointer;
          background: darkcyan;
          color: white;
          transition: all 200ms;
        }
      }

      & + .form {
        margin-top: 1rem;
      }

      .row {
        display: flex;
      }
    }

    .error {
      text-align: center;
      margin-top: 1rem;
      color: tomato;
    }

    .loading {
      text-align: center;
      margin-top: 1rem;
      font-size: 1.5rem;
    }

    .emptyList {
      text-align: center;
      margin-top: 1rem;
      color: tomato;
    }

    .items {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: $color;
        width: 250px;
        background: $container_color;
        border-radius: 1rem;
        box-sizing: border-box;

        p {
          font-size: 1.1rem;
          font-weight: 500;
          letter-spacing: 3px;
          text-align: center;
          margin: 0.6rem auto;
          padding: 0 0.5rem;
        }

        & + .item {
          margin-top: 1rem;
        }

        img {
          width: 100%;
          border-radius: 0 0 1rem 1rem;
        }

        &:hover {
          background: darkcyan;
          color: white;
        }
      }
    }
  }
}
