$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);

.header {
	margin-top: 1rem;
	text-align: center;

	a {
		display: inline-block;
		color: inherit;
		text-decoration: none;
		font-size: 1.5rem;
		background: $container_color;
		padding: 0.5rem 1rem;
		border-radius: 1rem;
		transition: all 200ms ease;

		&:hover {
			background: $color;
			color: $container_color;
		}
	}
}

.main {
	.title {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		background: $container_color;
		font-size: 1.5rem;
		padding: 0.5rem;
	}

	.items {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 1rem;

		.item {
			background: $container_color;
			transition: all 200ms ease;

			&:hover {
				background: greenyellow;

				a {
					color: $container_color;
				}
			}

			a {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				color: inherit;
				text-decoration: none;

				p {
					padding: 0.5rem;
					font-size: 1.5rem;
					letter-spacing: 1px;
				}
			}
		}
	}
}
