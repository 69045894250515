.header {
  display: flex;
  justify-content: center;
  margin-top: 18px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    text-align: center;

    background-color: rgb(33, 35, 41);
    color: inherit;
    text-decoration: none;
    width: 140px;
    height: 58px;
    font-size: 24px;
    transition: all 400ms ease;
    font-weight: 600;
    letter-spacing: 1px;

    &:hover {
      transition: none;
      cursor: pointer;
      background-color: darkcyan;
      color: white;
    }
  }
}

.content {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 65px;

  form {
    input {
      padding: 12px;
      font-size: 16px;
      background-color: rgb(33, 35, 41);
      border: none;
      color: rgb(150, 149, 155);
      border-radius: 12px;
      text-align: center;
      width: 220px;

      &:focus {
        outline: none;
      }
    }
  }

  .items {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .loading {
      text-align: center;
    }

    .item {
      display: flex;
      background-color: rgb(33, 35, 41);
      padding: 12px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      width: 350px;

      .play {
        border: none;
        width: 24px;
        height: 24px;
        background: none;
        filter: invert(75%);
        transition: all 200ms ease;

        img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          cursor: pointer;
          filter: invert(100%);
        }
      }

      .text {
        margin-left: 10px;
        letter-spacing: 1px;
        width: 100%;
      }

      .delete {
        border: none;
        width: 20px;
        height: 20px;
        background: none;
        transition: all 200ms ease;
        margin-left: 4px;
        border-radius: 50%;

        img {
          width: 20px;
          height: 20px;
          filter: invert(35%);
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(255, 100, 100, 0.5);

          img {
            filter: invert(100%);
          }
        }
      }
    }
  }

  .player {
    display: flex;
    position: fixed;
    bottom: 0;
    margin-bottom: 8px;
    background-color: rgb(33, 35, 41);
    padding: 12px;
    border-radius: 12px;
    align-items: center;
    width: 500px;
    border: 1px dashed rgba(150, 149, 155, 0.5);

    button {
      width: 24px;
      height: 24px;
      border: none;
      background: none;
      // margin-right: 6px;
      filter: invert(75%);
      transition: all 200ms ease;

      .img_pause {
        width: 24px;
        height: 24px;
      }

      .img_arrow_back {
        width: 24px;
        height: 24px;
      }

      &:hover {
        cursor: pointer;
        filter: invert(100%);
      }
    }

    .btn_next {
      margin-right: 12px;

      .img_arrow_next {
        transform: rotateZ(180deg);
        width: 24px;
        height: 24px;
      }
    }

    .btn_close {
      margin-left: 6px;

      .img_close {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      width: 100%;
      text-align: center;
      font-weight: 600;
      letter-spacing: 1px;
    }

    input {
      margin-right: 6px;
      width: 100px;
      accent-color: rgb(150, 149, 155);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
