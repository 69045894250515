$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);

.main {
	margin-bottom: 2rem;

	> header {
		display: flex;

		a {
			color: $color;
			text-decoration: none;
			background-color: $container_color;
			border-radius: 1rem;
			padding: 0.8rem 1rem;
			margin: 1.5rem auto;
			font-size: 2rem;
			transition: all 200ms;
			font-weight: 700;

			&:hover {
				background: darkcyan;
				color: white;
				transition: all 200ms;
			}
		}
	}

	> .collection {
		&:not(:nth-of-type(1)) {
			margin-top: 2rem;
		}

		> .title {
			text-align: center;
			font-size: 1.7rem;
			letter-spacing: 1px;
			background: $container_color;
			padding: 0.3rem;
		}

		> .items {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 1.5rem 2rem 0 2rem;

			> .item {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: -25px;
				transform: rotate(5deg);
				transition: all 200ms ease;
				border: 2px dashed $color;

				&:nth-child(1) {
					z-index: 1;
				}

				&:hover {
					cursor: pointer;
					z-index: 1;
					transform: rotate(5deg) translateY(10px);
				}

				img {
					width: min(40vw, 200px);
					height: min(60vw, 300px);
					display: flex;
				}
			}
		}
	}
}
