$bg_color: rgb(21, 22, 28);
$container_color: rgb(33, 35, 41);
$color: rgb(150, 149, 155);
$sun_filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);

.main {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 2rem;
	width: 100%;
	height: 100%;

	.title {
		font-size: min(14vw, 10rem);
	}

	.subtitle {
		font-size: min(8vw, 5rem);
	}

	a {
		color: $color;
		text-decoration: none;
		font-size: min(8vw, 3rem);
		transition: all 200ms;

		&:hover {
			color: white;
			transition: all 200ms;
		}
	}

	.list {
		margin-top: 1rem;
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;

		> li {
			transition: all 200ms ease;

			&:hover {
				transform: translateY(-6px);
			}
		}
	}
}
